import React from 'react';
import { AnimatePresence } from 'framer-motion';
import type { GatsbyBrowser, ShouldUpdateScrollArgs } from 'gatsby';
import 'prismjs/themes/prism-tomorrow.css';
import './src/styles/global.css';

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({ element }) => (
  // @ts-ignore
  <AnimatePresence mode='wait'>{element}</AnimatePresence>
);

export const shouldUpdateScroll = (_args: ShouldUpdateScrollArgs) => false;
